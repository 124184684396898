import React, { useState } from "react";
import logo from "../assets/vignan_online.png";
import axios from "axios";
import { useNavigate } from 'react-router-dom';


const ExternalRegister = () => {
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [error, setErrror] = useState("")
    const navigate = useNavigate();

    async function registeruser(e) {
        e.preventDefault();
        await axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/api/users/signup/external`, {
                first_name: name,
                email: email,
                phone_number: phone
            })
            .then((resp) => {
                if (resp.status === 201) {
                    setTimeout(() => {
                        setName("")
                        setEmail("")
                        setPhone("")
                    }, 2000)
                }
                navigate('/login');
            })
            .catch((error) => {
                setErrror(error.response.data.message)
                setTimeout(() => {
                    setErrror("")
                }, 2000)

            });
    }

    return (
        <div>
            <div className="max-w-7xl 2xl:max-w-[90rem] m-auto bg-[#f9fafb]">
                <div>
                    <a href="/" className="cursor-pointer">
                        <div className="m-auto w-max mt-8">
                            <img src={logo} alt="logo" />
                        </div>
                    </a>
                    <div className="text-2xl my-4">Signup to your dashboard</div>
                    <div className="bg-white w-[23rem] m-auto rounded-lg shadow-xl">
                        <form className="pb-2" onSubmit={registeruser}>
                            <div className="text-start px-8 py-4">Name</div>
                            <input
                                type="text"
                                className="border pl-2 w-[20rem] py-2 rounded-lg outline-none"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter name..."
                                required
                            />
                            <div className="text-start px-8 py-4">Email</div>
                            <input
                                type="email"
                                className="border pl-2 w-[20rem] py-2 rounded-lg outline-none"
                                placeholder="Enter email..."
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <div className="text-start px-8 py-4">Phone</div>
                            <input
                                type="tel"
                                className="border pl-2 w-[20rem] py-2 rounded-lg outline-none"
                                placeholder="Enter Phone..."
                                value={phone}
                                onChange={(e) => {
                                    const input = e.target.value.replace(/\D/g, "");
                                    if (input.length <= 10) {
                                        setPhone(input);
                                    }
                                }}
                                maxLength={10}
                                required
                            />
                            {error !== "" && <div className="text-red-500">{error}</div>}
                            <button
                                className="bg-red-500 my-6 p-2 rounded-lg w-[20rem] m-auto cursor-pointer"
                                type="submit"
                            >
                                Register
                            </button>
                        </form>

                    </div>
                    <div className="text-xs my-6 text-[#818894]">
                        Copyright VIGNAN Online (Deemed to be University). All Rights Reserved.
                    </div>
                </div>
            </div>
        </div>
    )

}



export default ExternalRegister;