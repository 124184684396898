import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/home";
import SalesDashboard from "../pages/salesDashboard.jsx";
import StudentDashboard from "../pages/studentDashboard.jsx";
import UserInfo from "../pages/userInfo.jsx"
import Login from "../pages/login.jsx";
import RequireAuth from "../utils/RequireAuth.js";
import { useAuth } from "../Context/AuthContext.jsx";
import { Navigate } from "react-router-dom";
import ExternalRegister from "../pages/externalRegister.jsx"

function AppRoutes() {
  const { user } = useAuth();

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/sales"
        element={
          <RequireAuth>
            {["Admin", "SalesHead", "DepartmentHead", "SalesCounselor"].includes(user?.user_type) ? (
              <SalesDashboard />
            ) : (
              <Navigate to="/not-authorized" />
            )}
          </RequireAuth>

        }
      />
      <Route
        path="/student"
        element={
          <RequireAuth>
            <StudentDashboard />
          </RequireAuth>
        }
      />
      <Route
        path="/register"
        element={
          <ExternalRegister />
        }
      />
      <Route
        path="/sales/:id"
        element={
          <RequireAuth>
            <UserInfo />
          </RequireAuth>
        }
      />
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

export default AppRoutes;
